import React, { useEffect } from "react";
import { FastField, Form, Formik } from "formik";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import cn from "classnames";

import Button from "../Button";
import InputField from "../Input/inputField";
import TextArea from "../Input/textArea";
import { useToast } from "../../hooks";
import { usePostInquiry } from "../../hooks/useInquiry";
import Checkbox from "../Input/checkbox";
import {
  FORM_LABEL,
  initialInquiryValues,
  validationInquirySchema,
} from "../../utils/validate/inquiryForm";
import { CODE_ERROR } from "../../utils/constants";

function InquiryForm() {
  const { notify, toast } = useToast();
  const { handleSendInquiry, isSuccess, loading, error } = usePostInquiry();

  useEffect(() => {
    if (!loading && isSuccess) {
      notify("正常に送信されました");
    }

    if (!loading && !isSuccess && !error?.success) {
      if (error?.message === CODE_ERROR.LIMIT_REACHED) {
        notify(
          "お問い合わせメールの送信は1時間に5通までとなっております。",
          "error"
        );
      }
    }
  }, [isSuccess, loading, notify, error]);

  return (
    <Formik
      initialValues={initialInquiryValues}
      validationSchema={validationInquirySchema}
      onSubmit={async (values, actions) => {
        const {
          name_jp = "",
          email = "",
          content = "",
          isVerify = false,
          title = "",
        } = values || {};

        if (!name_jp || !email || !content || !isVerify) {
          notify("入力されていない項目があります。", "error");
          return;
        }

        const requestData = pick(
          { name_jp, email, content: content.trim(), title: title.trim() },
          Object.keys(initialInquiryValues)
        );

        // console.log(requestData);

        await handleSendInquiry(requestData);

        actions.resetForm(initialInquiryValues);
      }}
    >
      {(formikProps) => {
        const { isValid, isSubmitting, errors } = formikProps;
        const isDisabled = (isValid || !isSubmitting) && isEmpty(errors);

        return (
          <Form>
            <h3 className="text-2xl text-main-black font-bold text-center mb-[30px]">
              お問合せ専用フォーム
            </h3>
            <FastField
              title={`${FORM_LABEL.name_jp}:`}
              labelInfo="【必須】"
              name="name_jp"
              component={InputField}
              type="text"
              placeholder="お名前を入力してください"
            />
            <FastField
              title={`${FORM_LABEL.email}:`}
              labelInfo="【必須】"
              name="email"
              component={InputField}
              type="text"
              placeholder="メールアドレスを入力してください"
              description="送信先メールアドレス：info.likematchapp@gmail.com"
            />
            <FastField
              title={`${FORM_LABEL.title}:`}
              name="title"
              component={InputField}
              type="text"
              placeholder="タイトルを入力してください。"
            />
            <FastField
              title={`${FORM_LABEL.content}:`}
              labelInfo="【必須】"
              name="content"
              component={TextArea}
              type="text"
              placeholder="お問合せ内容を入力してください。"
            />

            <FastField name="isVerify" component={Checkbox} />

            <Button
              type="submit"
              className={cn("mt-[30px]", {
                "cursor-pointer": isDisabled,
                "cursor-not-allowed": !isDisabled,
              })}
              disabled={!isDisabled}
              loading={loading}
            >
              送信する
            </Button>
            {toast}
          </Form>
        );
      }}
    </Formik>
  );
}

export default InquiryForm;
