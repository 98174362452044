const Checkbox = ({
  field,
  form,
  text = "個人情報保護方針についての同意",
}) => {
  const { errors } = form;
  const { name, value } = field;

  const handleChangeValue = (e) => {
    form.setFieldValue(name, e.target.checked);
  };

  return (
    <div className="w-full">
      <div className="flex items-center gap-2">
        <input
          name="isEvify"
          type="checkbox"
          checked={!!value}
          onChange={handleChangeValue}
          className="w-[18px] h-[18px] text-white bg-gray-100 rounded border-gray-300 focus:ring-blue-500 accent-[#21A5B6]"
        />
        <span>{text}</span>
      </div>
      <p className="min-h-[24px] text-red text-[10px] mt-1">{errors[name]}</p>
    </div>
  );
};

export default Checkbox;
