import { useCallback, useState } from "react";
import { registerApi } from "../api/axiosClient";

export function usePostInquiry() {
  const [loading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleSendInquiry = useCallback(async (data) => {
    setLoading(true);

    try {
      const res = await registerApi.sendInquiry(data);
      if (res?.success) setSuccess(true);

      console.log(res);

      setLoading(false);
      setError(null);
    } catch (err) {
      setError(err?.response?.data);

      setLoading(false);
      setSuccess(false);
      throw err;
    }
  }, []);

  return { handleSendInquiry, isSuccess, loading, error };
}
