import React from "react";
import { useOnHeader, useScrollToTop } from "../../hooks/useScroll";
import classNames from "classnames";

import Image from "../Image";

import button_scroll_to_top from "../../assets/images/button_scroll_to_top.svg";

const ButtonToTop = ({ className }) => {
  // scroll backtotop
  const scrollToTop = useScrollToTop();

  const isOnHeader = useOnHeader();

  return (
    <button
      className={classNames(
        "flex-center fixed right-[15px] bottom-8 z-50 cursor-pointer rounded-full bg-[#E8F8FA] opacity-60 lg:right-10",
        className
      )}
      onClick={scrollToTop}
      style={{ display: isOnHeader ? "none" : "flex" }}
    >
      <span className="flex-center h-full w-full">
        <Image
          width={48}
          height={48}
          src={button_scroll_to_top}
          alt="back-to-top"
        />
      </span>
    </button>
  );
};

export default ButtonToTop;
