import React from "react";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const propsToats = {
  position: "top-right",
  autoClose: 1500,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  pauseOnFocusLoss: true,
  rtl: false,
};

export function useToast() {
  const notify = (message = "", type = "success") =>
    toast(message, { type, ...propsToats });

  return {
    notify,
    toast: <ToastContainer {...propsToats} />,
  };
}
