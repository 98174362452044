// @ts-nocheck
import React from "react";
import cn from "classnames";

import award_icon from "../../assets/icons/award.png";
import circle_icon from "../../assets/icons/circle.png";
import rectangle_icon from "../../assets/icons/rectangle.png";
import Image from "../Image";

const FREE_AND_PAID_LIST = [
  { title: "いいね！機能", freeMembership: true, paidMembership: true },
  {
    title: "つぶやきへの\nコメント可能",
    freeMembership: false,
    paidMembership: true,
  },
  {
    title: "プロフィール写真\nロック機能",
    freeMembership: true,
    paidMembership: true,
  },
  {
    title: "相手からのいいね!\n及び足あと閲覧可能",
    freeMembership: true,
    paidMembership: true,
  },
  {
    title: "メッセージ",
    freeMembership: "1通320円",
    paidMembership: true,
  },
  {
    title: "コミュニティの\n新規作成可能",
    freeMembership: true,
    paidMembership: true,
    last: true,
  },
];

const renderIcon = (membership) => {
  if (typeof membership === "string") {
    return (
      <div className="lg:text-5xl text-sm whitespace-nowrap max-w-full">
        {membership}
      </div>
    );
  }
  return (
    <Image
      src={membership ? circle_icon : rectangle_icon}
      className="lg:w-20"
    />
  );
};

const rootClassName = (last) =>
  cn(
    "w-4/12 whitespace-pre-wrap flex-center px-1 py-2.5 pt-[26px] lg:pt-40 overflow-hidden",
    {
      "pb-6 rounded-b-lg lg:rounded-b-3xl": last,
    }
  );

const FreeAndPaid = () => {
  return (
    <div className="mt-4">
      <div className="flex gap-3.5 lg:gap-12 font-bold justify-between">
        <div className="w-7/12 pr-4" />
        <div className="w-4/12 bg-[#F2F2F2] px-1 py-2.5 flex items-end text-center justify-center lg:pt-10 rounded-t-lg lg:text-5xl lg:rounded-t-3xl">
          無料会員
        </div>
        <div className="text-center w-4/12 bg-[#E8F8FA] px-1 py-2.5 rounded-t-lg lg:pt-10 lg:text-5xl lg:rounded-t-3xl">
          <Image src={award_icon} className="mx-auto lg:w-32" />
          有料会員
        </div>
      </div>
      {FREE_AND_PAID_LIST.map(
        ({ title, freeMembership, paidMembership, last }) => (
          <div
            key={title}
            className="flex gap-3.5 lg:gap-12 font-bold justify-between"
          >
            <div
              className={cn(
                "w-7/12 whitespace-pre-wrap text-right text-sm lg:text-5xl pr-4 pt-[26px] lg:pt-40",
                { "pb-6": last }
              )}
            >
              {title}
            </div>
            <div
              className={cn(rootClassName(last), "text-[#9C9C9C] bg-[#F2F2F2]")}
            >
              {renderIcon(freeMembership)}
            </div>
            <div className={cn(rootClassName(last), "bg-[#E8F8FA]")}>
              {renderIcon(paidMembership)}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default FreeAndPaid;
