import "@fontsource/noto-sans-jp";
import * as yup from "yup";
import * as yupLocaleJA from "./utils/yupJA";
import { text_required } from "./utils/validate/inquiryForm";

import LandingPage from "./containers/LandingPage";
import "./App.css";

yup.setLocale({
  ...yupLocaleJA.suggestive,
  mixed: {
    ...yupLocaleJA.suggestive.mixed,
    required: text_required,
  },
});

function App() {
  return (
    <div className="max-w-[420px] lg:max-w-screen-xl mx-auto bg-white">
      <LandingPage />
    </div>
  );
}

export default App;
