import React from "react";
import classNames from "classnames";

const Text = ({ className, children, ...rest }) => (
  <div
    className={classNames(
      "w-full lg:px-2.5 text-[15px] lg:text-3xl font-normal text-main-black",
      className
    )}
    {...rest}
  >
    {children}
  </div>
);

export default Text;
