import axios from "axios";
import qs from "qs";

const API_ROOT =
  process.env.NEXT_PUBLIC_SERVER_URI || "https://like.thang.app/api/v1";
const TIMEOUT = process.env.TIMEOUT || 10000;

const axiosClient = axios.create({
  baseURL: API_ROOT,
  timeout: TIMEOUT,
  // headers: {
  //   "content-type": "application/x-www-form-urlencoded",
  // },
});

axiosClient.interceptors.request.use(async (config) => {
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    console.log({ error });
    throw error;
  }
);

export const registerApi = {
  submitRegisterEmail: (formdata) => {
    const url = "/contact";
    var data = qs.stringify({
      email: formdata.email,
    });
    const config = {
      method: "post",
      url,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };
    return axiosClient(config);
  },
  sendInquiry: (body) => {
    const url = "/inquiry";
    const config = {
      method: "post",
      url,
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    };
    return axiosClient(config);
  },
};
