import * as yup from "yup";
import { MAX_LEN_NAME, MAX_LEN_REASON, MAX_LEN_TITLE } from "../constants";

// eslint-disable-next-line no-useless-escape
const EMAIL_REGEX = /^([a-zA-Z0-9\.\@\_\-\+]+)$/g;
export const text_required = "を入力してください。";

export const initialInquiryValues = {
  name_jp: "",
  email: "",
  title: "",
  content: "",
  isEvify: false,
};

export const FORM_LABEL = {
  name_jp: "お名前",
  email: "メールアドレス",
  title: "タイトル",
  content: "お問合せ内容",
  isVerify: "個人情報保護方針", // checkbox verify
};

export const validationInquirySchema = yup.object().shape({
  name_jp: yup
    .string()
    .trim()
    .required(`${FORM_LABEL.name_jp}${text_required}`)
    .max(MAX_LEN_NAME, `${MAX_LEN_NAME}文字以内で入力してください。`)
    .label(FORM_LABEL.name_jp),

  email: yup
    .string()
    .trim()
    .email(`無効なメールアドレスです`)
    .required(`${FORM_LABEL.email}${text_required}`)
    .matches(EMAIL_REGEX, `正しいメールアドレスを入力してください`)
    .label(FORM_LABEL.email),

  title: yup
    .string()
    .trim()
    .max(MAX_LEN_TITLE, `${MAX_LEN_TITLE}文字以内で入力してください。`)
    .label(FORM_LABEL.title),

  content: yup
    .string()
    .trim()
    .required(`${FORM_LABEL.content}${text_required}`)
    .max(MAX_LEN_REASON, `${MAX_LEN_REASON}文字以内で入力してください。`)
    .label(FORM_LABEL.content),

  isVerify: yup
    .boolean()
    .required(`個人情報保護方針をチェックしてください。`)
    .oneOf([true], "個人情報保護方針をチェックしてください。"),
});
