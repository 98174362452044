import React from "react";
import classNames from "classnames";

const Spin = ({ size = 20, color }) => {
  return (
    <svg
      width={size}
      height={size}
      className="animate-spin text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      style={{ color }}
    >
      <circle
        className="opacity-25 bg-black"
        cx={12}
        cy={12}
        r={10}
        stroke="currentColor"
        strokeWidth={4}
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );
};

export default function Button({
  type = "button",
  children,
  className,
  loading,
  ...props
}) {
  return (
    <button
      type={type}
      className={classNames(
        "bg-[#21A5B6] p-3 text-white font-black text-base rounded-3xl border border-solid border-[#E8F8FA] w-full disabled:bg-[#BDCCCE] flex-center",
        className
      )}
      {...props}
    >
      {loading && (
        <span className="mr-2 ">
          <Spin />
        </span>
      )}
      {children}
    </button>
  );
}
