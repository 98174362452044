import React from "react";
import classNames from "classnames";

const Title = ({ className, children }) => (
  <div
    className={classNames(
      "w-full text-xl lg:text-4xl font-bold text-main-black",
      className
    )}
  >
    {children}
  </div>
);

export default Title;
