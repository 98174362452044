import cn from "classnames";

const InputField = ({
  field,
  form,
  type,
  placeholder,
  disabled,
  className = "",
  title = "",
  labelInfo = "",
  description = "",
}) => {
  const { errors } = form;
  const { name } = field;

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-1 mb-1 font-bold">
        {title && <div>{title}</div>}
        {labelInfo && <span className="text-[#EB5757]">{labelInfo}</span>}
      </div>
      <input
        {...field}
        id={name}
        style={errors[name] ? { border: "2px solid red" } : {}}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        className={cn(
          "bg-white rounded-lg border border-solid border-[#21A5B6] px-4 py-3",
          className
        )}
      />
      {description && (
        <div className="text-sm text-[#9C9C9C]">{description}</div>
      )}
      <span className="min-h-[24px] text-red text-[10px] mt-1">
        {errors[name]}
      </span>
    </div>
  );
};

export default InputField;
