// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import { Element } from "react-scroll";
import { Helmet, HelmetProvider } from "react-helmet-async";
import cn from "classnames";
import { TwitterTimelineEmbed } from "react-twitter-embed";

import { useWindowDimensions } from "../../hooks";

// import Button from "../../components/Button";
import Image from "../../components/Image";
import { Text, Title } from "../../components/Text";
import ButtonToTop from "../../components/ButtonToTop";
// import RegisterForm from "../../components/RegisterForm";
import InquiryForm from "../../components/InquiryForm";

import logo_icon from "../../assets/images/logo.svg";
import image_1 from "../../assets/images/image_1.png";
import bg_1 from "../../assets/images/bg_1.png";
import bg_1_1 from "../../assets/images/bg_1_1.png";
import image_1_1 from "../../assets/images/image_1_1.png";
import image_2 from "../../assets/images/image_2.png";
import bg_3 from "../../assets/images/bg_3.png";
import image_title_item_3 from "../../assets/images/title_item_3.svg";
import image_3_title_1 from "../../assets/images/image_3_title_1.svg";
import image_3_title_2 from "../../assets/images/image_3_title_2.svg";
import image_3_title_3 from "../../assets/images/image_3_title_3.svg";
import image_3_title_4 from "../../assets/images/image_3_title_4.svg";
import image_3_1 from "../../assets/images/image_3_1.png";
import image_3_2 from "../../assets/images/image_3_2.png";
import image_3_3 from "../../assets/images/image_3_3.png";
import image_3_4 from "../../assets/images/image_3_4.png";
import image_4 from "../../assets/images/image_4.png";
import image_4_title_1 from "../../assets/images/image_4_title_1.svg";
import image_4_title_2 from "../../assets/images/image_4_title_2.svg";
import image_4_title_3 from "../../assets/images/image_4_title_3.svg";
import bg_footer from "../../assets/images/bg_footer.png";
// import different from "../../assets/images/different_free_and_paid.png";
// import image_popup_trial from "../../assets/images/image_popup_trial.png";
// import triangle_down from "../../assets/images/triangle_down.svg";
// import twitter_icon from "../../assets/images/twitter_icon.svg";
// import facebook_icon from "../../assets/images/facebook_icon.svg";
// import instagram_icon from "../../assets/images/instagram_icon.svg";

import google_play_icon from "../../assets/images/google_play_icon.png";
import app_store_icon from "../../assets/images/app_store_icon.png";
// import { Modal } from "../../components/Modal";
// import ButtonClose from "../../components/Button/ButtonClose";

import FreeAndPaid from "../../components/FreeAndPaid.js";

// const SOCIAL_LINK = {
//   TWITTER: "https://twitter.com/like_match_app?s=21&t=-C6iY6K57ZZbcrNvw26Qag",
//   FACEBOOK: "/",
//   INSTAGRAM: "/",
// };

const APP_LINK = {
  AppleAppStore: "https://apps.apple.com/us/app/like-app/id1627685245",
  GooglePlaystore:
    "https://play.google.com/store/apps/details?id=com.likematchapp",
};

const AppLink = ({ className }) => (
  <div
    className={cn(
      "flex items-center justify-around w-full lg:gap-10 lg:px-72 lg:py-10 lg:mx-auto",
      className
    )}
  >
    <a href={APP_LINK.GooglePlaystore} target="_blank" rel="noreferrer">
      <Image src={google_play_icon} />
    </a>
    <a href={APP_LINK.AppleAppStore} target="_blank" rel="noreferrer">
      <Image src={app_store_icon} />
    </a>
  </div>
);

const Item1 = ({ isMobile }) => {
  // const scrollTo = useScrollTo();

  return (
    <div
      className="overflow-hidden w-full"
      style={{ backgroundImage: `url(${bg_1_1})` }}
    >
      <div
        className="flex flex-col justify-between space-y-[108px] bg-cover lg:h-[868px] lg:bg-center lg:px-6 overflow-hidden"
        style={{ backgroundImage: `url(${isMobile ? image_1 : bg_1})` }}
      >
        <div className="p-3">
          <a href="/">
            <Image src={logo_icon} className="mx-auto lg:w-48" />
          </a>
          <Image src={image_1_1} className="mt-7 ml-2 w-64 lg:w-[520px]" />
        </div>
        <div>
          <div className="p-4 lg:p-6 space-y-3 text-shadow w-full lg:w-1/2">
            <Title>
              <span className="text-[32px] lg:text-5xl">LIKE（ライク）</span>
              <br />
              友情結婚マッチングアプリ
            </Title>
            <Text>
              LIKEは恋愛関係や性的関係ではなく、利害や価値観が一致するパートナーを求めている方向けのマッチングアプリです。また趣味趣向の近い友人や仲間をみつけ、特別な友情関係を築くコミュニケーションアプリです。
            </Text>
          </div>
          {/* <div className="bg-white p-4">
          <Button onClick={() => scrollTo("formRegister")}>
            先行登録を申し込む
          </Button>
          <p className="text-xs mt-2.5 text-center">
            先行登録にはメールアドレスのご登録が必要となります。
          </p>
        </div> */}
        </div>
      </div>
    </div>
  );
};

const Item2 = () => (
  <div className="bg-white px-4 py-20 lg:px-6">
    <Image src={image_2} className="w-full" />
    <div className="lg:space-y-6 space-y-4 mt-8">
      <Title className="text-center">
        業界初の友情結婚マッチングアプリ！
        <br />
        多種多様なコミュニティ機能搭載！
      </Title>
      <Text className="lg:w-full lg:px-4">
        結婚相手やパートナーのみならず、コミュニティ機能を通して趣味趣向の近い友人や仲間をみつけることができます。また既存のコミュニティへ参加するだけでなく、自ら新しいコミュニティを作成することも可能です。
        <p className="mb-2" />
        LIKEはマッチング機能がなく、誰とでも自由に制限なくメッセージのやりとりができるため、より多くの相手と特別な関係を築くことができます。
      </Text>
    </div>
  </div>
);

const Item3 = () => {
  const ItemContentArray = [
    {
      id: 1,
      imageTitle: image_3_title_1,
      title: `お相手検索`,
      imageContent: image_3_1,
      content:
        "年齢や住居エリアなどの一般的な情報だけでなく、友情結婚や相手に望む条件、喫煙飲酒の有無など、細かい情報までを選択し検索できるため、より自分と価値観や条件の近い相手を探し出すことができます。",
    },
    {
      id: 2,
      imageTitle: image_3_title_2,
      title: `つぶやき`,
      imageContent: image_3_2,
      content:
        "マイページやタイムラインに日常を綴ったつぶやきを掲載することができるため、一般的な情報では知ることのできない、気になる相手の人柄や私生活を知ることができます。",
    },
    {
      id: 3,
      imageTitle: image_3_title_3,
      title: `コミュニティ`,
      imageContent: image_3_3,
      content:
        "コミュニティに参加することで、より自分の趣味趣向と近い相手や友人を探し出すことできます。1対1だけでなく、複数の相手とやりとりができるため、新たな出会いの場として楽しみの輪も広がります。また既存のコミュニティへ参加するだけでなく、自ら新しいコミュニティを作成することも可能です。",
    },
    {
      id: 4,
      imageTitle: image_3_title_4,
      title: `メッセージ`,
      imageContent: image_3_4,
      content:
        "LIKEはマッチング機能がなく、誰とでも自由に制限なくメッセージのやりとりをすることができます。気軽にメッセージのやりとりができるため、より多くの相手と特別な関係を築くことができます。",
    },
  ];

  return (
    <div
      className="bg-cover pb-[520px] lg:pb-[1200px] bg-bottom bg-[#d3bcf1]"
      style={{ backgroundImage: `url(${bg_3})` }}
    >
      <Image
        className="text-center py-10 px-[42px] w-full"
        src={image_title_item_3}
      />
      <div className="mx-4 bg-[#ffffffc2] px-2.5 py-6 flex flex-col gap-10 lg:mx-6 lg:px-4">
        {[...ItemContentArray].map(
          ({ id, imageTitle, title, imageContent, content }) => (
            <div key={id}>
              <div className="flex gap-4 items-center">
                <Image src={imageTitle} className="lg:w-20" />
                <Title className="text-[30px] lg:text-[48px] pr-5">
                  {title}
                </Title>
              </div>
              <div className="p-2.5">
                <Image src={imageContent} className="w-full" />
              </div>
              <Text
                className="px-2.5 lg:p-6 lg:mt-1"
                style={{ lineBreak: "anywhere" }}
              >
                {content}
              </Text>
            </div>
          )
        )}
      </div>
    </div>
  );
};

const Item4 = () => {
  const ItemContentArray = [
    {
      id: 1,
      imageItem: image_4_title_1,
      contentItem: "登録するには公的機関が発行した身分証明書での本人確認が必須",
    },
    {
      id: 2,
      imageItem: image_4_title_2,
      contentItem: "匿名・ニックネームでの利用もOK、また公開情報も選択可能",
    },
    {
      id: 3,
      imageItem: image_4_title_3,
      contentItem:
        "トラブルを未然に防ぐ監視体制と高度なセキュリティレベルを実現",
    },
  ];

  return (
    <div className="px-4 -mt-16 lg:-mt-24 lg:px-12 pb-10">
      <Title className="text-right text-2xl">
        LIKEの
        <br />
        安心安全の取り組み
      </Title>
      <div className="mt-6 space-y-4 lg:space-y-8">
        {[...ItemContentArray].map(({ id, imageItem, contentItem }) => (
          <div
            key={id}
            className="p-2 lg:p-6 bg-[#E8F8FA] shadow-base flex items-center gap-3 rounded-lg"
          >
            <Image src={imageItem} className="px-2 lg:w-12" />
            <Text>{contentItem}</Text>
          </div>
        ))}
      </div>
    </div>
  );
};

const DifferentialFreeAndPaid = () => (
  <div className="py-10 px-4 lg:px-12">
    <div className="text-center mb-8 lg:space-y-2">
      <Title className="text-2xl">無料会員と有料会員の違い</Title>
      <Text className="text-[#21A5B6] font-semibold text-sm lg:w-full">
        LIKEには有料会員限定の機能があります
      </Text>
    </div>
    {/* <Image src={different} /> */}
    <FreeAndPaid />
  </div>
);

const YouToAtLike = () => (
  <div className="bg-[#F7FCFC] w-full">
    <Image src={image_4} className="w-full p-[60px]" />
    <AppLink className="flex-col lg:flex-row px-20 l pb-10 gap-6" />
  </div>
);

const TwitterLink = ({ isMobile }) => (
  <div className="py-10 px-4 lg:px-12 mx-auto overflow-x-auto overflow-scroll">
    <TwitterTimelineEmbed
      sourceType="profile"
      screenName="like_match_app"
      noFooter
      options={{ height: isMobile ? 420 : 680 }}
    />
  </div>
);

const Item5 = () => (
  <div className="bg-white px-4 lg:px-6 py-10">
    {/* <Element name="formRegister">
      <Title className="mb-6">
        先行登録の
        <br />
        お申し込みはこちら
      </Title>
    </Element> */}
    <div className="text-xs lg:text-lg space-y-4 lg:px-6">
      <Title className="text-sm">注意事項</Title>
      <ul className="list-decimal px-4 space-y-1 font-light lg:space-y-4">
        <li>
          会員様に快適にサービスをご利用頂くためLIKEでは18歳未満のご利用を禁止しております。そのため会員登録の際、公的機関が発行した身分証明書にてご本人様の確認をさせて頂いております。
        </li>
        <li>
          LIKEでは個人情報の漏洩や悪質な嫌がらせを防ぐため、アプリ内のスクリーンショットは原則禁止とさせて頂いております。また運営サイドで迷惑行為と見做された場合、強制退会等のペナルティになるケースもございますので十分にご注意ください。
        </li>
        <li>
          違反報告を頂いた場合、運営サイドにて事実確認の調査を行います。事実内容によっては対象会員様への注意喚起はもちろん、強制退会処分を行います。アプリ内で不審な会員様をみつけた場合は、運営サイドへお知らせください。
        </li>
        <li>
          有料会員様の有料サービス利用期間は登録日より1ヶ月（30日）ごとです。（例:4月24日に登録した場合、5月23日まで利用可能）
        </li>
        <li>
          解約の申し出がない限り自動更新となります。更新日の1日前までに解約手続きを行えば翌月の月額料金は発生しません。
        </li>
        <li>
          更新日前に解約をされた場合でも残り期間の日割り返金は行っておりません。
        </li>
      </ul>
    </div>
  </div>
);

const Footer = () => (
  <div
    className="w-full flex flex-col items-start justify-start px-4 pt-5 pb-20 lg:pb-40 bg-cover lg:px-12"
    style={{ backgroundImage: `url(${bg_footer})` }}
  >
    <a href="/" className="w-[86px] lg:w-48">
      <Image src={logo_icon} />
    </a>
    <Text className="mt-1.5 lg:mt-4 text-[16px]">
      インターネット異性紹介事業届済
      <br />
      受理番号:30220039-000
    </Text>
    {/* <div className="flex-center flex-row gap-4"> */}
    {/* <a href={SOCIAL_LINK.TWITTER}>
        <Image src={twitter_icon} />
      </a> */}
    {/* <a href={SOCIAL_LINK.FACEBOOK}>
        <Image src={facebook_icon} />
      </a>
      <a href={SOCIAL_LINK.INSTAGRAM}>
        <Image src={instagram_icon} />
      </a> */}
    {/* </div> */}
  </div>
);

const LandingPage = () => {
  const title = "LIKE（ライク）友情結婚マッチングアプリ";
  const { width } = useWindowDimensions();
  const isMobile = width < 1024;
  // const [isOpen, setIsOpen] = useState(false);

  // const closeModal = () => {
  //   setIsOpen(false);
  // };

  // const renderModal = () => (
  //   <Modal
  //     isOpen={isOpen}
  //     onRequestClose={closeModal}
  //     className="w-[372px] lg:w-[690px] top-1/3"
  //   >
  //     <Image src={image_popup_trial} className="w-full -ml-2" />
  //     <ButtonClose onClick={closeModal} />
  //   </Modal>
  // );

  // useEffect(() => {
  //   setIsOpen(true);
  // }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={title} />
      </Helmet>

      <div className="shadow-base">
        <Item1 isMobile={isMobile} />
        <AppLink className="bg-[#F7FCFC] px-4 py-5 gap-5" />
        <Item2 />
        <Item3 />
        <Item4 />
        <DifferentialFreeAndPaid />
        <YouToAtLike />
        <TwitterLink isMobile={isMobile} />

        <div className="bg-[#E8F8FA] px-4 lg:px-6 py-10">
          <InquiryForm />
        </div>

        <Item5 />
        <Footer />

        <ButtonToTop />

        {/* {isOpen && renderModal()} */}
      </div>
    </HelmetProvider>
  );
};

export default LandingPage;
