import React from "react";
import classNames from "classnames";

export default function Image({ src, className, ...props }) {
  return (
    <img
      src={src}
      className={classNames("object-cover", className)}
      alt=""
      {...props}
    />
  );
}
